import { Nav } from '../components/nav';
import { Footer } from '../components/footer';
import type { ParentProps } from 'solid-js';

type Props = ParentProps;

export function BaseLayout(props: Props) {
	return (
		<>
			<div class="relative z-10 flex min-h-screen w-screen flex-col justify-stretch">
				<Nav />

				<main id="content" class="relative z-0 flex shrink-0 grow flex-col pb-6 md:pb-12">
					{props.children}
				</main>
				<footer class="shrink-0 bg-white pb-4">
					<Footer />
				</footer>
			</div>
		</>
	);
}
