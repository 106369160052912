import { createAsync } from '@solidjs/router';
import { Show, Suspense } from 'solid-js';
import { Meta } from '@solidjs/meta';
import { AccessProductsCtx, getAccessProducts } from '../providers/card';
import { getConfigValue } from '../modules/config';
import { createPersisted } from '../providers/persistence-store';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

const persistenceTTLMs = /* ms */ 1000 * /* s */ 60 * /* m */ 60 * /* h */ 24 /*d*/ * 5;

export default function CardsPageLayout(props: RouteSectionProps) {
	const data = createAsync(() => getAccessProducts({}), { deferStream: true });
	const expires = Date.now() + persistenceTTLMs;
	createPersisted({ promoCode: expires });

	return (
		<>
			<Meta name="og:image" content={`${getConfigValue('IMAGE_HOST')}/digital/photo/og/access.png?format=jpeg`} />
			<Suspense>
				<Show when={data()?.products}>
					{(products) => <AccessProductsCtx.Provider value={products}>{props.children}</AccessProductsCtx.Provider>}
				</Show>
			</Suspense>
		</>
	);
}

export const route = {
	preload: async () => {
		return getAccessProducts({});
	},
} satisfies RouteDefinition;
